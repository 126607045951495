import types from './types';

const {
  VALIDATE_PHONE, VALIDATE_PHONE_SUCCESS, VALIDATE_PHONE_FAIL,
  CLEAR,
} = types;

export const initialState = {
  validating: false,
  validated: false,
  validatingAlternate: false,
  validatedAlternate: false,
  phone: null,
  alternatePhone: null,
  error: {},
  errorAlternate: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case VALIDATE_PHONE: {
      return {
        ...state,
        validating: true,
        validated: false,
        error: {},
        phone: null,
      };
    }
    case VALIDATE_PHONE_SUCCESS:
      return {
        ...state,
        validating: false,
        validated: true,
        phone: action.result,
        error: {},
      };
    case VALIDATE_PHONE_FAIL:
      return {
        ...state,
        validating: false,
        validated: false,
        error: action.error,
      };
    case CLEAR:
      return { ...initialState };
    default:
      return state;
  }
}
