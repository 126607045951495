import { createStore, applyMiddleware, compose } from 'redux';
import createRootReducer from './modules/reducer';
import authMiddleware from './modules/auth/middleware';
import bookingMiddleware from './modules/booking/middleware';
import clientMiddleware from './middleware/clientMiddleware';
import dialogMiddleware from './modules/dialog/middleware';
import errorMiddleware from './middleware/errorMiddleware';
import guestMiddleware from './modules/guest/middleware';
import paymentMiddleware from './middleware/paymentMiddleware';

export default ({ initialState, client }) => {
  const middlewares = [
    clientMiddleware(client),
    authMiddleware,
    bookingMiddleware,
    dialogMiddleware,
    errorMiddleware,
    guestMiddleware,
    paymentMiddleware,
  ];

  let finalCreateStore;
  // Build the store for development and test to include of redux dev tools.
  if ((process.env.NODE_ENV !== 'production' || process.env.STAGING === 'true') && typeof window !== 'undefined') {
    const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle
    finalCreateStore = composeEnhancers(
      applyMiddleware(...middlewares)
    )(createStore);
  }
  else {
    // Build the store for production without redux dev tools
    finalCreateStore = applyMiddleware(...middlewares)(createStore);
  }

  // Redux store
  const store = finalCreateStore(
    createRootReducer(),
    initialState || {},
  );

  return { store };
};
